.footer-form-section {
  padding: 8.8rem 0rem 0rem 0rem;
  background: var(--fourth-color);
}
.footer-form-section h2 {
  color: var(--third-color);
}
.footer-form-section p {
  color: var(--white-color);
}
.footer-form-section .footer-form-section--grid-6 {
  display: grid;
  grid-template-columns: 1fr;
}

@media (min-width: 992px) {
  .footer-form-section {
    padding: 8.8rem 0rem 0rem 0rem;
    background: url("../img/hand-phone.png") var(--fourth-color);
    background-size: auto auto;
    background-position: 0px 22px;
    background-repeat: no-repeat;
  }
  .footer-form-section .footer-form-section--grid-6 {
    grid-template-columns: 50% 50%;
  }
}
@media (min-width: 1200px) {
  .footer-form-section .footer-form-section--grid-6 {
    grid-template-columns: 45% 55%;
  }
}
@media (min-width: 1400px) {
  .footer-form-section .footer-form-section--grid-6 {
    grid-template-columns: 40% 60%;
  }
}
@media (min-width: 1600px) {
  .footer-form-section .footer-form-section--grid-6 {
    grid-template-columns: 25% 75%;
  }
}